const navBar = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        id: 1,
        _name: "CSidebarNavItem",
        name: "Transaction",
        to: "/transaction",
        icon: "cil-list",
        key: "transaction",
      },
      {
        id: 2,
        _name: "CSidebarNavItem",
        name: "Event List",
        to: "/event",
        icon: "cil-list",
        key: "event",
      },
      {
        id: 3,
        _name: "CSidebarNavItem",
        name: "Promocode",
        to: "/promocode",
        icon: "cil-list",
        key: "promocode",
      },
      {
        id: 4,
        _name: "CSidebarNavItem",
        name: "Banner",
        to: "/banner",
        icon: "cil-list",
        key: "banner",
      },
      {
        id: 5,
        _name: "CSidebarNavItem",
        name: "Admin",
        key: "admin",
        icon: "cil-list",
        to: "/admin",
      },
      {
        id: 6,
        _name: "CSidebarNavItem",
        name: "Role",
        key: "role",
        icon: "cil-list",
        to: "/role",
      },
      {
        id: 7,
        _name: "CSidebarNavItem",
        name: "Brand",
        key: "brand",
        icon: "cil-list",
        to: "/brand",
      },
      {
        id: 8,
        _name: "CSidebarNavItem",
        name: "Category",
        key: "category",
        icon: "cil-list",
        to: "/category",
      },
      {
        id: 9,
        _name: "CSidebarNavItem",
        name: "Branch",
        key: "branch",
        icon: "cil-list",
        to: "/branch",
      },
      {
        id: 10,
        _name: "CSidebarNavItem",
        name: "Service",
        key: "service",
        icon: "cil-list",
        to: "/service",
      },
      {
        id: 11,
        _name: "CSidebarNavItem",
        name: "Dashboard",
        key: "dashboard",
        icon: "cil-list",
        to: "/dashboard",
      },
      {
        id: 16,
        _name: "CSidebarNavItem",
        name: "Price Setting",
        key: "pricesetting",
        icon: "cil-list",
        to: "/price-setting",
      },
      {
        id: 17,
        _name: "CSidebarNavItem",
        name: "Package / Test Setting",
        key: "packagetestsetting",
        icon: "cil-list",
        to: "/package-test-setting",
      },
    ],
  },
];

export default navBar;
